<!--
Ако за уреда има подаден самоотчет във темп-таблицата, показваме
иконка за нотификация, и при кликане излиза меню за избор на стойност
(може да има повече от един самоотчет, с различни канали).
Добавка: Тук вече показваме и визуалните отчети от монтажниците.
-->

<template>
	<v-menu v-if="show" offset-y>
		<template #activator="{on,attrs}">
			<v-btn text color="primary" v-bind="attrs" v-on="on">
				<v-icon left>mdi-alert-circle-outline</v-icon>
				Отчет
			</v-btn>
		</template>
		<v-sheet>

			<template v-if="valuesGodishen.length">
				<div class="subtitle-1 pa-2">Годишен/месечен отчет</div>
				<v-divider/>
				<v-simple-table dense>
					<thead>
					<tr>
						<th>Дата</th>
						<th>Памет</th>
						<th>Показание</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(val, index) in valuesGodishen" :key="'godishen'+index" @click="$emit('select', val)" class="clickable">
						<td><date-format :date="val.date" /></td>
						<td><number-format :number="val.value_mem" :decimals="decimals" /></td>
						<td class="font-weight-bold"><number-format :number="val.value" :decimals="decimals" /></td>
					</tr>
					</tbody>
				</v-simple-table>
			</template>

			<template v-if="valuesVisual.length">
				<div class="subtitle-1 pa-2">Визуален отчет / монтажник</div>
				<v-divider/>
				<v-simple-table dense>
					<thead>
					<tr>
						<th>Дата</th>
						<th>Памет</th>
						<th>Показание</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(val, index) in valuesVisual" :key="'visual'+index" @click="$emit('select', val)" class="clickable">
						<td><date-format :date="val.date" /></td>
						<td><number-format :number="val.value_mem" :decimals="decimals" /></td>
						<td class="font-weight-bold"><number-format :number="val.value" :decimals="decimals" /></td>
					</tr>
					</tbody>
				</v-simple-table>
			</template>

			<template v-if="valuesSelf.length">
				<div class="subtitle-1 pa-2">Самоотчет</div>
				<v-divider/>
				<v-simple-table dense>
					<thead>
					<tr>
						<th>Тип</th>
						<th>Дата</th>
						<th>Показание</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(val, index) in valuesSelf" :key="'self'+index" @click="$emit('select', val)" class="clickable">
						<td><v-icon
							:title="title(val.samootchet_type)"
							v-text="icon(val.samootchet_type)"
						/></td>
						<td><date-format :date="val.date" /></td>
						<td class="font-weight-bold"><number-format :number="val.value" :decimals="decimals" /></td>
					</tr>
					</tbody>
				</v-simple-table>
			</template>

		</v-sheet>
	</v-menu>
</template>

<style scoped>
tr.clickable > td {
	cursor: pointer;
	user-select: none;
}
</style>

<script>
import {ITTIModelBase} from "@/ittijs/ITTIModel";
import {optionsSamootchet, OtchetType} from "@/ittijs/Nomenclatures";
import DateFormat from "@/ittijs/components/DateFormat";
import NumberFormat from "@/ittijs/components/NumberFormat.vue";

class Model extends ITTIModelBase {
	constructor() {
		super('SelfReportValues');
	}
	getForDeviceSession(session, device){
		return this.fetch('getForDeviceSession', {session, device});
	}
}

const model = new Model;

export default {
	components: {NumberFormat, DateFormat},
	props: [
		'device',
		'session',
		'readonly',
		'decimals',
	],
	data(){
		return {
			loading: false,
			values: null,
		}
	},
	async mounted() {
		this.loading = true;
		this.values = await model.getForDeviceSession(this.session, this.device);
		this.loading = false;
	},
	computed: {
		show(){
			if (this.loading) return false;
			return this.valuesSelf.length || this.valuesVisual.length || this.valuesGodishen.length;
		},
		valuesSelf(){
			return this.values?.self?.map(item => ({
				...item,
				otchet_type: OtchetType.SAMOOTCHET,
			})) ?? [];
		},
		valuesVisual(){
			return this.values?.visual?.map(item => ({
				...item,
				otchet_type: OtchetType.REDOVEN,
			})) ?? [];
		},
		valuesGodishen(){
			return this.values?.godishen?.map(item => ({
				...item,
				otchet_type: OtchetType.REDOVEN,
			})) ?? [];
		},
	},
	methods: {
		icon(type){
			return (optionsSamootchet.find(o=>o.value==type) || {})['icon'];
		},
		title(type){
			return (optionsSamootchet.find(o=>o.value==type) || {})['text'];
		},
		color(type){
			return (optionsSamootchet.find(o=>o.value==type) || {})['color'];
		},
	},
}
</script>